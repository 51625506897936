// app global css in SCSS form
.q-page {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  padding: 15px 15px;
}

.mobile-page {
  margin-left: auto;
  margin-right: auto;
}

.q-page-narrow {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding: 0px;
}

.footer {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.header {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
